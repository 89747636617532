import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { Button } from './Button';
import { TextInputField } from './text-input-field';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {NumberInputField} from "./NumberInputField";
import {gql, useLazyQuery} from "@apollo/client";
import {CompaniesCheckboxList} from "./subscription-fields/companies-input";
import {SubscriptionFormQuery} from "../__generated__/SubscriptionFormQuery";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {BooleanInput} from "./boolean-input-field";

const SubscriptionSchema = yup.object().shape({
  name: yup.string().trim().required().label("name"),
  companyIds: yup.array().of(
    yup.string().required()
  ).required().min(1).label("Companies"),
  credits: yup.number().required().label("credits"),
  expirationMonths: yup.number().required().label("Expiration in months"),
  qaExpirationHours: yup.number().required().label("Qa session expiration in hours"),
  botSessionExpirationHours: yup.number().required().label("Bot session expiration in hours"),
  fixedPrice: yup.number().required().label("Fixed price"),
  pricePerSession: yup.number().required().label("Price per credit"),
  automaticRenewal: yup.boolean().label("Automatic renewal"),
  freeSessions: yup.number().required().label("Free credits")
})

export interface SubscriptionValues {
  companyIds: string[]
  name: string
  credits: number
  expirationMonths: number
  qaExpirationHours: number
  botSessionExpirationHours: number
  fixedPrice: number
  pricePerSession: number
  automaticRenewal: boolean
  freeSessions: number
}

export interface SubscriptionFormProps {
  subscription?: {
    id: string
    name: string | null
    freeSessions: number
    pricePerSession: number
    automaticRenewal: boolean
    credits: number
    expirationMonths: number
    qaExpirationHours: number
    botSessionExpirationHours: number
    fixedPrice: number
    companySubscriptions: [{companyId: string}]
  }
  companyId: string,
  className?: string
  onCancel?: () => void;
  onSubmit: SubmitHandler<SubscriptionValues>;
}


export const SubscriptionForm: React.FunctionComponent<SubscriptionFormProps> = ({ subscription, companyId, className, onCancel, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");

  const defaultValues: DefaultValues<SubscriptionValues> = {
    name: subscription?.name ?? '',
    credits: subscription?.credits ?? 1000,
    freeSessions: subscription?.freeSessions ?? 0,
    fixedPrice: subscription?.fixedPrice ?? 0,
    pricePerSession: subscription?.pricePerSession ?? 0.24,
    automaticRenewal: subscription?.automaticRenewal ?? false,
    expirationMonths: subscription?.expirationMonths ?? 12,
    qaExpirationHours: subscription?.qaExpirationHours ?? 4,
    botSessionExpirationHours: subscription?.botSessionExpirationHours ?? (7 * 24),
    companyIds: subscription?.companySubscriptions.map(x => x.companyId)
  }

  const { register, handleSubmit, formState, watch, setValue, getValues  } = useForm<SubscriptionValues>({ defaultValues, resolver: yupResolver(SubscriptionSchema) })
  const companyIds = watch("companyIds");

  const [getCompanies, { data, loading, error }] = useLazyQuery<SubscriptionFormQuery>(gql`
    query SubscriptionFormQuery {
      companies {
        id
        name
      }
    }
  `, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    getCompanies()
  }, [])

  if (loading){
    return <p>{t('loading')}</p>
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="43">{t('search')}</label>
            <input type="search" value={filter} onChange={e => setFilter(e.target.value)} className="w-20 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:number-sm border-gray-300 rounded-md"/>
          </div>
          <p className="text-sm font-medium text-gray-700">
            <span>{t('selected')}: </span>
            <span>{companyIds ? companyIds.filter(x => x.length > 0)?.length : 0}</span>
          </p>
          <div className="">
            <CompaniesCheckboxList label={t('companies')} companies={data?.companies.filter(x => filter == '' || x.name.toLowerCase().includes(filter.toLowerCase()))} getValues={getValues} setValue={setValue} {...register('companyIds')} errors={formState.errors}>
            </CompaniesCheckboxList>
            <div className="col-span-12 mt-4">
              <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('credits')} {...register('credits')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('free_credits')} {...register('freeSessions')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('expiration_months')} {...register('expirationMonths')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('qa_modules_expiration_hours')} {...register('qaExpirationHours')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('bot_session_expiration_hours')} {...register('botSessionExpirationHours')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('fixed_price')} {...register('fixedPrice')} errors={formState.errors} />
            </div>
            <div className="col-span-12 mt-4">
              <NumberInputField label={t('price_per_credit')} step="0.01" {...register('pricePerSession')} errors={formState.errors} />
            </div>
            <div className="col-span-12">
              <label className="flex w-full py-4 justify-center items-baseline">
                <span className="flex-1 block text-sm font-medium text-gray-700">{t('automatic_renewal')}
                </span>
                <div>
                  <BooleanInput label={t('automatic_renewal')} {...register('automaticRenewal')} errors={formState.errors} className={'mt-0'} />
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}
