import * as React from 'react';
import { gql } from '@apollo/client'
import { SubmitHandler } from 'react-hook-form';
import { CardType } from '../../__generated__/globalTypes'
import { TextareaField } from '../textarea-field';
import { FileInputField } from '../FileInputField';
import { NumberInputField } from '../NumberInputField';
import useDirectUploadMutation from '../../hooks/direct-upload-mutation';
import { VideoCard } from '../../types'
import { BaseForm, BaseFormProps } from './base-form';
import { CardValues } from '.'
import { VideoInputField, videoTypeTest, videoSizeTest } from '../VideoInputField';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const VideoFormSchema = {
  video: yup.object({
    blobId: yup.mixed().required().test(videoTypeTest).test(videoSizeTest).label("Video"),
  }),
  mediaMessageText: yup.string().label("Text"),
  mediaMessageTyping: yup.number().integer().required().min(0).max(10000).label("Typing"),
}

export const VideoFormFragment = gql`
  fragment VideoFormFragment on VideoCard {
    videoUrl
    mediaMessageText
    mediaMessageTyping
  }
`

export interface CardVideoValues extends CardValues {
  cardType: CardType.VIDEO
  video: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  mediaMessageText: string
  mediaMessageTyping: number
}

export function defaultValuesForVideoCard(card: VideoCard | null): CardVideoValues {
  return {
    cardType: CardType.VIDEO,
    title: card?.title || '',
    video: {
      blobId: card?.videoUrl || null,
      _destroy: false
    },
    mediaMessageText: card?.mediaMessageText ?? '',
    mediaMessageTyping: card?.mediaMessageTyping ?? 6
  }
}

export interface VideoFormProps extends BaseFormProps<CardVideoValues> {
  card: VideoCard
}

export const VideoForm: React.FC<VideoFormProps> = props => {
  const { onSubmit: unusedOnSubmit, ...formProps } = props
  const directUpload = useDirectUploadMutation()
  const { t, i18n } = useTranslation();

  const onSubmit: SubmitHandler<CardVideoValues> = async values => {
    let signedBlobId = values?.video.blobId instanceof FileList
      ? await directUpload(values.video.blobId![0])
      : null

    return props.onSubmit({ ...values, video: { _destroy: values.video._destroy, blobId: signedBlobId }})
  }

  return (
    <BaseForm<CardVideoValues> onSubmit={onSubmit} {...formProps}>
      {({ register, setValue, formState }) => (
        <React.Fragment>
          <VideoInputField
            preview={props.card.videoUrl}
            onRemove={() => {setValue('video', {blobId: null, _destroy: true}, { shouldValidate: true })}}
            previewComponent={props => <video controls {...props} />}
            errors={formState.errors}
            {...register('video.blobId')}
          />
          <TextareaField maxLength={1000} label={t('text')} rows={10} {...register('mediaMessageText')} errors={formState.errors}/>
          <NumberInputField label={t('typing')} {...register('mediaMessageTyping', { valueAsNumber: true })} errors={formState.errors}/>
        </React.Fragment>
      )}
    </BaseForm>
  )
}
