import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import {ChatAltIcon, DotsVerticalIcon, UserGroupIcon, UserIcon} from '@heroicons/react/solid'
import { DepartmentsOverviewRoute_DeleteDepartmentMutation, DepartmentsOverviewRoute_DeleteDepartmentMutationVariables } from '../__generated__/DepartmentsOverviewRoute_DeleteDepartmentMutation';
import { DepartmentsOverviewRoute_DepartmentTable_departments } from '../__generated__/DepartmentsOverviewRoute_DepartmentTable_departments';
import { DepartmentsOverviewRoute_DepartmentTableRow_department } from '../__generated__/DepartmentsOverviewRoute_DepartmentTableRow_department';
import { DepartmentsOverviewRouteQuery } from '../__generated__/DepartmentsOverviewRouteQuery';
import { Button } from '../components/Button';
import useHasChanged from '../hooks/has-changed';
import { VFCwF } from '../types'
import {SearchBox} from "../components/Searchbox";

import {useTranslation} from "react-i18next";

interface DepartmentTableRowProps {
  userRole: string
  department: DepartmentsOverviewRoute_DepartmentTableRow_department;
  onDelete: (id: string) => void
}

const DepartmentTableRow: VFCwF<DepartmentTableRowProps> = ({ userRole, department, onDelete }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <tr>
      <th
        scope="row"
        className="px-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900"
      >
        <Link
          className="block truncate hover:text-gray-600"
          to={department.id}
        >
          {department.name}
        </Link>
      </th>
      <td className="px-8 py-3 text-center">{department.language}</td>
      <td className="px-8 py-3 text-center">
        <>
          { department.kind == 'scheduled' ? (
            <span className="material-symbols-outlined">schedule</span>
          ) : department.kind == 'template' ? (
            <span className="material-symbols-outlined">star</span>
          ) : department.kind == 'available' ? (
            <span className="material-symbols-outlined">eventlist</span>
          ) : (
            <span className="material-symbols-outlined">checklist</span>
          )}
          {department.templateDepartmentId != null && (
              <span className="material-symbols-outlined">star</span>
          )}
        </>
      </td>
      <td className="px-8 py-3">{ (department.kind == 'scheduled' || department.kind == 'template' || department.kind == 'available') ? (
        <span>{department.whatsappBroadcastTemplateName}</span>
      ) : (
        <span>-</span>
      )}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        {department.botUsersCount > 0 && (
          <Link
            className="block"
            to={`/${department.companyId}/bot_users?department=${department.id}`}
          >
            <div className="flex items-center space-x-2" aria-hidden="true">
              <div className="flex flex-shrink-0 -space-x-1">
                {Array(Math.min(department.botUsersCount, 4))
                  .fill(null)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="bg-gray-300 max-w-none h-6 w-6 flex items-center justify-center rounded-full ring-2 ring-white"
                    >
                      <UserIcon className="h-4 w-4" />
                    </div>
                  ))}
              </div>

              {department.botUsersCount > 4 && (
                <span className="flex-shrink-0 text-xs leading-5 font-medium">
                  {`+${department.botUsersCount - 4}`}
                </span>
              )}
            </div>
          </Link>
        )}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        {department.orders.length > 0 ? (
          <span>{(department.orders.map((x) => x.amount).reduce((prev, next) => prev + next)) - department.botUsersCount}</span>
        ) : (
          <span></span>
        )}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        {department.botModulesCount > 0 && (
          <Link
            className="block"
            to={department.id}
          >
            <div className="flex items-center space-x-2" aria-hidden="true">
              <div className="flex flex-shrink-0 -space-x-1">
                {Array(Math.min(department.botModulesCount, 4))
                  .fill(null)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="bg-gray-300 max-w-none h-6 w-6 flex items-center justify-center rounded-full ring-2 ring-white"
                    >
                      <ChatAltIcon className="h-4 w-4" />
                    </div>
                  ))}
              </div>

              {department.botModulesCount > 4 && (
                <span className="flex-shrink-0 text-xs leading-5 font-medium">
                  {`+${department.botModulesCount - 4}`}
                </span>
              )}
            </div>
          </Link>
        )}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        <Menu>
          <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" />
          </MenuButton>
          <MenuList className="w-50">
            <MenuLink as={Link} to={department.id}>
              {t('details')}
            </MenuLink>
            {department.templateDepartmentId == null &&
              <MenuLink as={Link} to={`${department.id}/edit`} state={{ backgroundLocation: location }}>
                {t('edit')}
              </MenuLink>
            }
            {department.kind == 'template' &&
                <MenuLink as={Link} to={`${department.id}/edit_visuals`}>
                  {t('edit_visuals')}
                </MenuLink>
            }
            {(userRole == 'superadmin' || userRole == 'admin') &&
              <>
                <MenuLink as={Link} to={`${department.id}/manage_bot_users`} state={{ backgroundLocation: location }}>
                  {t('manage_bot_users')}
                </MenuLink>

                <MenuItem onSelect={() => onDelete(department.id)}>
                  {t('delete')}
                </MenuItem>
              </>
            }
          </MenuList>
        </Menu>
      </td>
    </tr>
  )
}

DepartmentTableRow.fragments = {
  department: gql`
    fragment DepartmentsOverviewRoute_DepartmentTableRow_department on Department {
      id
      name
      botModulesCount
      orders {
        amount 
        price
      }
      botUsersCount
      companyId
      language
      kind
      whatsappBroadcastTemplateName
      templateDepartmentId
    }
  `
}

interface DepartmentTableProps {
  userRole: string
  departments: DepartmentsOverviewRoute_DepartmentTable_departments[]
  onDelete: (id: string) => void;
}

const DepartmentTable: VFCwF<DepartmentTableProps> = ({ userRole, departments, onDelete }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="overflow-auto border border-gray-200 rounded-lg relative">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3" >{t('name')}</th>
            <th scope="col" className="px-8 py-3" >{t('language')}</th>
            <th scope="col" className="px-8 py-3" >{t('type')}</th>
            <th scope="col" className="px-8 py-3" >{t('template')}</th>
            <th scope="col" className="px-8 py-3" >{t('bot_users')}</th>
            <th scope="col" className="px-8 py-3">{t('department_credits')}</th>
            <th scope="col" className="px-8 py-3" >{t('bot_modules')}</th>
            <th scope="col" className="pr-8 py-3" >
              <span className="sr-only">{t('actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100">
          {departments.map(department => (
            <DepartmentTableRow userRole={userRole} key={department.id} department={department} onDelete={onDelete} />
          ))}

          {departments.length === 0 && (
            <tr>
              <td className="px-8 py-3 text-sm text-gray-500" colSpan={4}>
                {t('none_available')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

DepartmentTable.fragments = {
  departments: gql`
    fragment DepartmentsOverviewRoute_DepartmentTable_departments on Department {
      id
      ...DepartmentsOverviewRoute_DepartmentTableRow_department
    }

    ${DepartmentTableRow.fragments.department}
  `
}

interface DepartmentsOverviewRouteProps {
  companyId: string;
  userRole: string;
}

const DepartmentsOverviewRoute: React.VFC<DepartmentsOverviewRouteProps> = ({ userRole, companyId }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const { data, loading, error, refetch } = useQuery<DepartmentsOverviewRouteQuery>(gql`
    query DepartmentsOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        noBotUsersAttachedToDepartmentsYet
        departments {
          ...DepartmentsOverviewRoute_DepartmentTable_departments
        }
      }
    }

    ${DepartmentTable.fragments.departments}
  `, {
    variables: {
      companyId
    }
  });

  const [deleteDepartment] = useMutation<DepartmentsOverviewRoute_DeleteDepartmentMutation, DepartmentsOverviewRoute_DeleteDepartmentMutationVariables>(gql`
    mutation DepartmentsOverviewRoute_DeleteDepartmentMutation($input: DeleteDepartmentMutationInput!) {
      deleteDepartment(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['DepartmentsOverviewRouteQuery']
  })

  function onSearchSelect(id: string) {
    navigate(`/${companyId}/departments/${id}`)
  }

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch departments when the modal is closed by a navigate action.
      refetch()
    }
  });

  function onDepartmentDelete(id: string) {
    deleteDepartment({ variables: { input: { id } } })
  }

  if (error){
     return <div data-error={JSON.stringify(error)}></div>
  }
  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('departments')}
          </h1>
          <div className="flex items-center space-x-3">
            <SearchBox
              className="w-64"
              options={data?.company!.departments ?? []}
              disabled={loading}
              onSelect={onSearchSelect}
            />
            <Button disabled={loading} onClick={() => navigate('new', { state: { backgroundLocation: location } })}>
              {t('create')}
            </Button>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <>
            {data?.company!.noBotUsersAttachedToDepartmentsYet && (
                <>
                  <a className={`mb-4 text-white relative rounded-lg px-6 py-5 shadow-sm flex items-start justify-between space-x-3 bg-orange-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 color-white`} href={'/' + companyId + '/departments'} >
                    <div className="flex flex-col text-white">
                      <h2 className="text-xl font-semibold text-white truncate mb-4">{t('attach_bot_users')}</h2>
                      <div className="text-xs font-medium text-white" dangerouslySetInnerHTML={{__html: t('attach_bot_users_to_department_explanations') || ''}}></div>
                    </div>
                    <div className="bg-orange-400 rounded-full p-2 self-center">
                      <UserGroupIcon className="w-6 h-6 text-white" viewBox="0 0 20 22"/>
                    </div>
                  </a>
                </>
            )}
            <DepartmentTable
              userRole={userRole}
              departments={data!.company!.departments}
              onDelete={onDepartmentDelete}
            />
          </>
        )}
      </main>
    </div>
  );
}

export default DepartmentsOverviewRoute;
