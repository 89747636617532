import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation, useQuery } from '@apollo/client';
// import { BotUserDetailsForm_botUser } from '../__generated__/BotUserDetailsForm_botUser';
import {BotUserForm_botUser} from "../__generated__/BotUserForm_botUser";
import {Button, Intent} from './Button';
import { TextInputField } from './text-input-field';
import { SelectField } from './select-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {makeDeleteBotUserMutation} from "../queries/BotUsersQueryHandler";
import { useNavigate, useParams } from 'react-router-dom';

const BotUserSchema = yup.object().shape({
  firstName: yup.string().trim().required().label("First name"),
  lastName: yup.string().trim().required().label("Last name"),
  email: yup.string().trim().email().label("Email").when('mobile', {
    is: (mobile: string) => !mobile,
    then: yup.string().email().required(),
    otherwise: yup.string().email()
  }),
  mobile: yup.string().label("Mobile").when('email', {
    is: (email: string) => !email,
    then: yup.string().trim().required().label("Mobile"),
    // then: yup.string().trim().matches(/^\+[0-9]{11}$/, { message: "Mobile is required and must start with + and rest digits" }).required(),
    // otherwise: yup.string().trim().matches(/^\+[0-9]{11}$/, { excludeEmptyString: true, message: "Mobile must start + and rest digits" })
  })
}, [['email', 'mobile']])

export interface BotUserValues {
  firstName: string
  lastName: string
  testUser: boolean
  email: string | null
  mobile: string | null
  departmentId: string
  botUserType: string
}

export interface BotUserFormProps {
  companyId: string
  departments?: DepartmentValues[]
  departmentId?: string | null
  botUser?: BotUserForm_botUser
  className?: string
  onCancel?: () => void;
  onSubmit: SubmitHandler<BotUserValues>;
}

interface DepartmentValues {
  id: string
  name: string
}

const BOT_USER_TYPES = ["whatsapp", "web"];

export const BotUserForm: VFCwF<BotUserFormProps> = ({ companyId, botUser, departments, departmentId, className, onCancel, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const defaultValues: DefaultValues<BotUserValues> = {
    firstName: botUser?.firstName ?? '',
    lastName: botUser?.lastName ?? '',
    email: botUser?.email ?? '',
    mobile: botUser?.mobile ?? '',
    testUser: botUser?.testUser ?? false,
    botUserType: botUser?.botUserType ?? 'whatsapp',
    departmentId: departmentId || botUser?.departmentId || departments?.[0].id
  }

  const [deleteBotUser] = makeDeleteBotUserMutation()
  function onBotUserDelete(id: string) {
    if(window.confirm(t('are_you_sure'))) {
      deleteBotUser({variables: {input: {id}}})
          .then(({data}) => navigate(`/${companyId}/bot_users`))
    }
  }

  const { register, handleSubmit, formState } = useForm<BotUserValues>({ defaultValues, resolver: yupResolver(BotUserSchema) })

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            {departments &&
              <div className="col-span-6">
                <SelectField label={t('department')} {...register('departmentId')} errors={formState.errors}>
                  {departments.map(department => (
                    <option key={department.id}  value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </SelectField>
              </div>
            }
            <div className="col-span-3">
              <TextInputField label={t('first_name')} {...register('firstName')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('last_name')} {...register('lastName')} errors={formState.errors}/>
            </div>
            <div className="col-span-3">
              <TextInputField label={t('email')} type="email" placeholder="ex: you@example.com" { ...register('email') } errors={formState.errors}/>
            </div>
            <div className="col-span-3">
              <TextInputField label={t('mobile')} type="tel" placeholder="ex: +31612345678" { ...register('mobile') } errors={formState.errors}/>
            </div>
            <div className="col-span-6">
              <SelectField label={t('type')} {...register('botUserType')} errors={formState.errors}>
                {BOT_USER_TYPES.map(type => (
                  <option key={type}  value={type}>
                    {type}
                  </option>
                ))}
              </SelectField>
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right flex justify-end align-items-center">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
          {botUser != null && botUser.id != null &&
            <>
              <div className="ml-2"></div>
              <Button intent={Intent.Danger} type="submit" onClick={() => onBotUserDelete(botUser.id)} disabled={formState.isSubmitting}>
                {t('delete')}
              </Button>
            </>
          }
        </div>
      </div>
    </form>
  )
}

BotUserForm.fragments = {
  botUser: gql`
    fragment BotUserForm_botUser on BotUser {
      id
      firstName
      lastName
      email
      mobile
      departmentId
      botUserType
      company {
        id
        departments {
          id
          name
        }
      }
    }
  `
}
