import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DepartmentValues } from '../components/DepartmentForm';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";
import {Appearance, Button} from "../components/Button";
import {postToBackend} from "../libraries/librequestutils";

interface DepartmentsUpdateRouteProps {
  companyId: string;
}

const DepartmentsImportBotUsersRoute: React.FC<DepartmentsUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { departmentId } = useParams<'departmentId'>();
  const { t, i18n } = useTranslation();
  const uploadBotUsersInput: React.RefObject<any> = React.useRef(null);
  const [importErrors, setImportErrors] = React.useState([]);
  const [importSuccess, setImportSuccess] = React.useState("");


  function onCancel() {
    navigate(`/${companyId}/bot_users?department=${departmentId}`)
  }

  function onExportClick() {
     window.open(`/template-import.xlsx`, '_blank');
  }

  async function handleFileInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = evt.target.files?.[0];
    if (!file) return;

    const result = await postToBackend(
      `/${companyId}/bot_users/import`,
      {
        file: file,
        department_id: departmentId// || data?.company!.departments[0]?.id
      }
    )
    const jsonBody = await result.json();

    if (!!jsonBody.errors.length) {
      setImportErrors(jsonBody.errors);
    }
    else if (!!jsonBody.succeeded.length) {
      setImportSuccess(`${jsonBody.succeeded.length} bot user(s) imported succesfully`)
    }
  }

  return (
    <SlideOver title={t('edit_department')} onClose={onCancel}>
      <>
        {importErrors && importErrors.length > 0 &&
          <div className="fixed top-3 right-5 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
            <div className="text-sm text-white">
              <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
              {importErrors.join(', ')}
            </div>
          </div>
        }
        {importSuccess &&
          <div className="fixed top-3 right-5 px-5 py-4 bg-green-500 bg-white drop-shadow-lg">
            <div className="text-sm text-white flex">
              <div style={{height: "30px"}} className="mr-4 inline-block px-3 py-1 rounded-full bg-green-500 text-white drop-shadow-lg font-extrabold">i</div>
              <div className="text-white">
                <div>{t('updated')}</div>
              </div>
            </div>
          </div>
        }

        <h2 className="mt-4 font-medium text-gray-900">{t('import_file')}</h2>
        <p className="text-gray-500">{t('import_new_bot_users')}</p>
        <input ref={uploadBotUsersInput} onClick={(event)=> event.target.value = null} onChange={handleFileInputChange} type="file" className="sr-only"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"/>
        <Button onClick={() => {uploadBotUsersInput.current.click()}}>
          Import
        </Button>
        <h2 className="mt-4 font-medium text-gray-900">{t('dont_have_file_ready')}</h2>
        <p className="text-gray-500">{t('download_example')}</p>
        <Button onClick={() => onExportClick()}>
          {t('download_template')}
        </Button>
        <p className="mt-16">
          <Button appearance={Appearance.Outline} onClick={() => onCancel()}>
            {t('cancel')}
          </Button>
        </p>
      </>
    </SlideOver>
  )
}

export default DepartmentsImportBotUsersRoute;
