import * as React from 'react'
import { gql } from '@apollo/client'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { CardType } from '../../__generated__/globalTypes'
import { NumberInputField } from '../NumberInputField';
import { TextareaField } from '../textarea-field';
import { TextCard } from '../../types'
import { CardValues } from '.'
import { BaseForm, BaseFormProps } from './base-form';
import { ErrorMessage } from '@hookform/error-message';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const TextFormSchema = {
  textMessageText: yup.array().of(
    yup.object({
      text: yup.string().required().label("Text"),
      typing: yup.number().integer().min(0).max(10000).required().label("Typing")
    })
  ).required().min(1).label("Text message")
}

export const TextFormFragment = gql`
  fragment TextFormFragment on TextCard {
    textMessageText {
      text
      typing
    }
  }
`

export interface CardTextValues extends CardValues {
  cardType: CardType.TEXT,
  textMessageText: { text: string, typing: number }[];
}

export function defaultValuesForTextCard(card: TextCard | null): CardTextValues {
  return {
    cardType: CardType.TEXT,
    title: card?.title || '',
    textMessageText: (card?.textMessageText || []).map(textMessageText => ({
      text: textMessageText.text,
      typing: textMessageText.typing
    }))
  }
}

const TextMessageTextForm: React.FC = (props) => {
  const { register, trigger, clearErrors, formState } = useFormContext<CardTextValues>();
  const { fields, append, remove } = useFieldArray<CardTextValues>({ name: 'textMessageText' });
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (!fields.length) {
      append({typing: 2})

      setTimeout(() => {
        clearErrors()
      }, 0)
    }
  }, [fields])
  return (
    <React.Fragment>
      {fields.map((field, textMessageTextIdx) => (
        <div key={field.id}>
          <div className="space-y-6">
            <TextareaField maxLength={1000} showHint={true} label={t('text')} rows={10} {...register(`textMessageText.${textMessageTextIdx}.text`)} errors={formState.errors}/>
            <NumberInputField label={t('typing')} {...register(`textMessageText.${textMessageTextIdx}.typing`, { valueAsNumber: true })} errors={formState.errors}/>
          </div>

          <button type="button" onClick={() => remove(textMessageTextIdx)}>
            {t('remove')}
          </button>
        </div>
      ))}

      <div>
        <button type="button" onClick={() => append({typing: 2})}>
          {t('add')}
        </button>
        <ErrorMessage errors={formState.errors} className="mt-2 text-sm text-red-600" name="textMessageText" as="p" />
      </div>
    </React.Fragment>
  )
}

export interface TextFormProps extends BaseFormProps<CardTextValues> {}

export const TextForm: React.FC<TextFormProps> = props => {
  return (
    <BaseForm<CardTextValues> {...props}>
      <TextMessageTextForm {...props}/>
    </BaseForm>
  )
}
