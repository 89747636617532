import * as React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid'
import cx from 'clsx'
import { BotModulesDetailsRoute_CreateCardMutation, BotModulesDetailsRoute_CreateCardMutationVariables } from '../__generated__/BotModulesDetailsRoute_CreateCardMutation';
import { BotModulesDetailsRoute_DeleteCardMutation, BotModulesDetailsRoute_DeleteCardMutationVariables } from '../__generated__/BotModulesDetailsRoute_DeleteCardMutation';
import { BotModulesDetailsRoute_UpdateBotModuleMutation, BotModulesDetailsRoute_UpdateBotModuleMutationVariables } from '../__generated__/BotModulesDetailsRoute_UpdateBotModuleMutation';
import { BotModulesDetailsRoute_UpdateCardMutation, BotModulesDetailsRoute_UpdateCardMutationVariables } from '../__generated__/BotModulesDetailsRoute_UpdateCardMutation';
import { BotModulesDetailsRouteQuery } from '../__generated__/BotModulesDetailsRouteQuery';
import { CardType } from '../__generated__/globalTypes';
import { CardForm, CardValues } from '../components/card-form';
import { CardList } from '../components/CardList';
import { Button, Appearance, Intent } from '../components/Button';
import { sendModule } from '../libraries/librequestutils';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {MessageType, useBotUser} from "../web_chat/hooks/useBotUser";
import Root from "../web_chat/components/Root";
import {useActionCable} from "../web_chat/actionCable";

interface BotModulesDetailsRouteProps {
  companyId: string;
}

const BotModulesDetailsRoute: React.VFC<BotModulesDetailsRouteProps> = ({ companyId }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate()
  const { botModuleId } = useParams<'botModuleId'>();
  const { cardId } = useParams<'cardId'>();
  const [selected, setSelected] = React.useState<{ id: null, botModuleId: string, cardType: CardType } | { id: string, botModuleId: string, cardType: CardType, title: string } | null>(null);
  const [showIframe, setShowIframe] = React.useState<boolean>(false)
  const [updated, setUpdated] = useState(false);

  const toggleIframe = () => {
    setShowIframe(false);
    setTimeout(function(){
      setShowIframe(true)
    }, 100)
  }

  const { data, loading, error } = useQuery<BotModulesDetailsRouteQuery>(gql`
    query BotModulesDetailsRouteQuery($botModuleId: ID!) {
      botModule(id: $botModuleId) {
        id
        name
        companyId

        ...CardList_botModule
      }
    }

    ${CardList.fragments.botModule}
  `, {
    variables: {
      botModuleId: botModuleId as string
    }
  });

  const [updateBotModule] = useMutation<BotModulesDetailsRoute_UpdateBotModuleMutation, BotModulesDetailsRoute_UpdateBotModuleMutationVariables>(gql`
    mutation BotModulesDetailsRoute_UpdateBotModuleMutation($input: UpdateBotModuleMutationInput!) {
      updateBotModule(input: $input) {
        botModule {
          id
        }
      }
    }
  `)

  const [createCard] = useMutation<BotModulesDetailsRoute_CreateCardMutation, BotModulesDetailsRoute_CreateCardMutationVariables>(gql`
    mutation BotModulesDetailsRoute_CreateCardMutation($input: CreateCardMutationInput!) {
      createCard(input: $input) {
        card {
          id
          botModuleId
          cardType
          title
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['BotModulesDetailsRouteQuery', 'CardFormQuery', 'QuestionAnalyticsTableQuery', 'BotUserAnalyticsTableQuery']
  })

  const [updateCard] = useMutation<BotModulesDetailsRoute_UpdateCardMutation, BotModulesDetailsRoute_UpdateCardMutationVariables>(gql`
    mutation BotModulesDetailsRoute_UpdateCardMutation($input: UpdateCardMutationInput!) {
      updateCard(input: $input) {
        card {
          id
          botModuleId
          cardType
          title
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['BotModulesDetailsRouteQuery', 'CardFormQuery', 'QuestionAnalyticsTableQuery', 'BotUserAnalyticsTableQuery']
  })

  const [deleteCard] = useMutation<BotModulesDetailsRoute_DeleteCardMutation, BotModulesDetailsRoute_DeleteCardMutationVariables>(gql`
    mutation BotModulesDetailsRoute_DeleteCardMutation($input: DeleteCardMutationInput!) {
      deleteCard(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['BotModulesDetailsRouteQuery', 'CardFormQuery', 'QuestionAnalyticsTableQuery', 'BotUserAnalyticsTableQuery']
  })

  async function handleCardDelete() {
    if (selected!.id) {
      await deleteCard({ variables: { input: { id: selected!.id } } })
    }

    navigate(`/${companyId}/bot_modules/${botModuleId}`)
  }

  async function handleCardFormSubmit(values: CardValues) {
    let cardData = await (selected!.id
      ? updateCard({
          variables: {
            input: { id: selected!.id, ...values }
          }
        }).then(({ data }) => {
          setSelected(data!.updateCard!.card)
          setUpdated(true)
        })
      : createCard({
          variables: {
            input: { botModuleId: selected!.botModuleId, ...values }
          }
        }) .then(({data}) => navigate(`/${companyId}/bot_modules/${botModuleId}/cards/${data!.createCard!.card.id}`))
    )
  }

  function handleCardsSorted(cards: { id: string }[]) {
    updateBotModule({
      variables: { input: { id: botModuleId as string, cardIds: cards.map(card => card.id) } },
      update: (cache, { data: { updateBotModule: { botModule } }  }) => {
        cache.modify({
          id: cache.identify(botModule),
          fields: {
            cards() {
              return cards;
            }
          }
        })
      }
    })
  }

  function onCreateClick(cardType: CardType) {
    setSelected({ id: null, botModuleId: botModuleId as string, cardType });
  }


  React.useEffect(() => {
    if (!cardId) return;

    const card = data?.botModule?.cards?.find((card) => card.id === cardId) || null
    setSelected(card);
  }, [data, cardId])

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/bot_modules`} className="hover:text-gray-400">
              {t('bot_modules')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>

            <h1 className="text-gray-900 truncate">
              {t('editing')} {data ? data?.botModule!.name : '...'}
            </h1>
          </div>
          <div className="flex justify-content-center ml-2">
            <Menu as="div" className="ml-2 relative flex-shrink-0">
              <div>
                <Menu.Button className="mr-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500" type="button" onClick={(e) => setShowIframe(true)}>
                  {t('show_live_preview')}
                </Menu.Button>
              </div>
            </Menu>
            <Menu as="div" className="ml-2 relative flex-shrink-0">
              <div>
                <Menu.Button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                  {t('create_card')}
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {['image', 'text', 'quiz', 'open', 'video'].map(cardType => (
                      <Menu.Item key={cardType}>
                        {({ active }) => (
                          <button
                            className={cx(active ? 'text-blue-600' : 'text-gray-700', 'group flex items-center w-full text-left px-4 py-2 text-sm capitalize')}
                            onClick={() => onCreateClick(cardType.toUpperCase() as CardType)}
                          >
                            {cardType}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </header>

      <main className="mt-8">
        <div id="department_detail_view"></div>
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (!data || !data?.botModule || data?.botModule?.companyId !== companyId) ? (
          <p>{t('not_found')}</p>
        ) : (
          <>
            <div className="grid grid-cols-4 gap-x-6">
              <div className="col-span-1">
                <h2 className="text-lg font-semibold text-gray-900">
                  {t('cards')}
                </h2>

                <CardList
                  routePart="bot_modules"
                  className="mt-2"
                  companyId={companyId}
                  botModule={data?.botModule}
                  onSorted={handleCardsSorted}
                />
              </div>

              <div className="col-span-3">
                {selected ? (
                  <React.Fragment>
                    <h2 className="text-lg font-semibold text-gray-900">
                      {selected.id ? `${t('editing')} ${selected.title}` : `${t('creating_new_card')}`}
                    </h2>

                    <CardForm
                      className="mt-2"
                      card={selected}
                      onSubmit={handleCardFormSubmit}
                      onCancel={handleCardDelete}
                      onDelete={handleCardDelete}
                      companyId={companyId}
                    />
                  </React.Fragment>
                ) : (
                  <div>
                    {t('please_select_or_create_card')}
                  </div>
                )}
              </div>
              {updated &&
                <div className="fixed top-3 right-3 px-5 py-4 bg-green-500 bg-white drop-shadow-lg items-center">
                  <div className="text-sm text-white flex">
                    <div style={{height: "30px"}} className="mr-4 inline-block px-3 py-1 rounded-full bg-green-500 text-white drop-shadow-lg font-extrabold">i</div>
                    <div className="text-white flex items-center">
                      <div>{t('updated')}</div>
                    </div>
                  </div>
                </div>
              }
            </div>
            {showIframe ? (
              <>
                <div className="web-preview">
                  <div className="inner">
                    <iframe className="w-100" src={`/webchat/preview?bot_module_id=${data?.botModule.id || 0 }&company_id=${companyId}`}></iframe>
                  </div>
                  <div className="web-preview-buttons">
                    <Button appearance={Appearance.Outline} type="button" onClick={(e) => setShowIframe(false)}>
                      {t('hide_live_preview')}
                    </Button>
                    <Button type="button" onClick={(e) => toggleIframe()}>
                      {t('refresh_live_preview')}
                    </Button>
                  </div>
                </div>
              </>
              ) : (
                <></>
            )}
          </>
          )}
      </main>
    </div>
  )
}

export default BotModulesDetailsRoute;
