import React, {useState} from 'react';
import { Link, useSearchParams, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Menu, MenuList, MenuButton, MenuItem, MenuLink, } from "@reach/menu-button";
import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { BotUsersOverviewRoute_BotUserTableRow_botUser } from '../__generated__/BotUsersOverviewRoute_BotUserTableRow_botUser';
import { BotUsersOverviewRoute_DeleteBotUserMutation, BotUsersOverviewRoute_DeleteBotUserMutationVariables } from '../__generated__/BotUsersOverviewRoute_DeleteBotUserMutation';
import { BotUsersOverviewRouteQuery,
         BotUsersOverviewRouteQueryVariables,
         BotUsersOverviewRouteQuery_company_botUsers,
         BotUsersOverviewRouteQuery_company_botUsers_edges_node } from '../__generated__/BotUsersOverviewRouteQuery';
import { Button } from '../components/Button';
import { SearchBox } from '../components/Searchbox';
import { Select } from '../components/select-field'
import useHasChanged from '../hooks/has-changed'
import { VFCwF } from '../types'
import cx from 'clsx'
import { postToBackend } from '../libraries/librequestutils';
import Popover from '../components/Popover';
import {loadBotUsers, makeDeleteBotUserMutation} from "../queries/BotUsersQueryHandler";
import {useTranslation} from "react-i18next";

interface BotUserTableRowProps {
  botUser: BotUsersOverviewRouteQuery_company_botUsers_edges_node
  onDelete: (id: string) => void
  companyId: string
  userRole: string
}

export const BotUserTableRow: VFCwF<BotUserTableRowProps> = ({ userRole, botUser, onDelete, companyId }) => {
  const [searchParams, _] = useSearchParams();
  const highlightIds = searchParams.get('highlight')
  const { t, i18n } = useTranslation();

  return (
    <tr className={cx({"animate-fade": highlightIds?.includes(botUser.id)})}>
      <th
        scope="row"
        className="px-8 py-3 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900">
        {botUser.anonymous ? (
          <span>{botUser.firstName} {botUser.lastName}</span>
        ) : (
          <>
            <Link className="block hover:text-gray-600" to={botUser.id}>
              {botUser.firstName} {botUser.lastName}
            </Link>
            {botUser?.department?.id &&
              <Link className="block truncate hover:text-gray-600" to={`/${companyId}/departments/${botUser.department.id}`}>
                {botUser.department.name}
              </Link>
            }
          </>
      )}
      </th>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium text-right">
        {botUser.botUserType == 'whatsapp' ? (<span className="material-symbols-outlined">smartphone</span>) : (<span className="material-symbols-outlined">forum</span>)}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium text-right">
        {(botUser.testUser) ? t('test_user') : ''}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium text-right">
        {(botUser.botUserType === 'web' ? botUser.emailVerified : botUser.mobileVerified) ? t('verified') : t('pending')}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium text-right">
        <div className="flex items-center">
          <div className="relative bg-gray-100 rounded-full w-24 h-2 overflow-hidden">
            <div className="absolute bg-blue-500 inset-y-0 left-0" style={{ width: `calc(${botUser.progress}%)` }}></div>
          </div>
          <div className="text-xs ml-2">
            <span className="whitespace-nowrap" >{t('progress')} </span>
            {`${Math.round((botUser.progress || 0))}%`}
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative bg-gray-100 rounded-full w-24 h-2 overflow-hidden">
            <div className="absolute bg-blue-500 inset-y-0 left-0" style={{ width: `calc(${botUser.quizScore}%)` }}></div>
          </div>
          <div className="text-xs ml-2">
            <span className="whitespace-nowrap" >{t('quiz_score')}</span>
            {`${Math.round((botUser.quizScore || 0))}%`}
          </div>
        </div>
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
        { ((userRole == 'admin' ||  userRole == 'superadmin') && !botUser.anonymous) &&
          <Menu>
            <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
              <span className="sr-only">{t('options')}</span>
              <DotsVerticalIcon className="h-5 w-5" />
            </MenuButton>
            <MenuList className="w-40">
              <MenuLink as={Link} to={botUser.id}>
                {t('details')}
              </MenuLink>
              <MenuItem onSelect={() => onDelete(botUser.id)}>
                {t('delete')}
              </MenuItem>
            </MenuList>
          </Menu>
        }
      </td>
    </tr>
  )
}

BotUserTableRow.fragments = {
  botUser: gql`
    fragment BotUsersOverviewRoute_BotUserTableRow_botUser on BotUser {
      id
      firstName
      testUser
      lastName
      botUserType
      emailVerified
      mobileVerified
      progress
      quizScore
      anonymous
      department {
        id
        name
      }
    }
  `
}

interface BotUserTableProps {
  companyId: string;
  departmentId?: string | null;
  onDelete: (id: string) => void;
  botUsers?: BotUsersOverviewRouteQuery_company_botUsers
  onCursorChange: Function
  userRole: string
}

const initialValue:Array<any> = []
export const BotUserTable: VFCwF<BotUserTableProps> = ({ userRole, companyId, departmentId, onDelete, botUsers, onCursorChange }) => {
  const filteredBotUsers = botUsers?.edges?.map(edge => edge?.node);
  const { t, i18n } = useTranslation();

  const [pages, setPages] = useState(initialValue)

  const onPreviousClicked = function(){
    let previousAfter = pages.pop();

    if (pages.length == 0){
      onCursorChange({ department: departmentId});
    } else {
      previousAfter = pages.pop()
      pages.push(previousAfter)
      onCursorChange({ department: departmentId, after: previousAfter});
    }
  }

  const onNextClicked = function(){
    pages.push(botUsers?.pageInfo.endCursor)
    onCursorChange({ department: departmentId, after: botUsers?.pageInfo.endCursor})
  }

  return (
    <div className="overflow-auto border border-gray-200 rounded-lg relative">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3">
              {t('name_department')}
            </th>
            <th scope="col" className="px-8 py-3 text-right">
            </th>
            <th scope="col" className="px-8 py-3 text-right">
            </th>
            <th scope="col" className="px-8 py-3 text-right">
              {t('status')}
            </th>
            <th scope="col" className="px-8 py-3 text-left">
              {t('scores')}
            </th>
            <th scope="col" className="pr-8 py-3 text-right">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tfoot className="bg-white border-t border-gray-200">
          <tr>
            <td className="px-8 py-3" colSpan={7}>
              <div className="flex justify-end">
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => onPreviousClicked()}
                    disabled={!botUsers?.pageInfo.hasPreviousPage}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>

                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => onNextClicked()}
                    disabled={!botUsers?.pageInfo.hasNextPage}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody className="bg-white divide-y divide-gray-100">
          <>
            {filteredBotUsers?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
              .map(botUser => <BotUserTableRow userRole={userRole} key={botUser?.id} companyId={companyId} botUser={botUser} onDelete={onDelete} />)
            }
            {!filteredBotUsers?.length && (
              <tr>
                <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                  {t('no_bot_modules_within_this_department')}
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  )
}

BotUserTable.fragments = {
  botUsers: gql`
    fragment BotUsersOverviewRoute_BotUserTableQuery_botUsers on Company {
      botUsers(departmentIds: $departmentIds, first: 15, after: $after, before: $before) {
        edges {
          node {
            id
            ...BotUsersOverviewRoute_BotUserTableRow_botUser
          }
        }

        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
      botUsersNameIds(departmentIds: $departmentIds) {
        id,
        fullName
      }
    }

    ${BotUserTableRow.fragments.botUser}
  `
}

interface BotUsersOverviewRouteProps {
  companyId: string;
  userRole: string;
}

const BotUsersOverviewRoute: React.VFC<BotUsersOverviewRouteProps> = ({ userRole, companyId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const departmentId = searchParams.get('department');
  const createParams = departmentId ? { search: createSearchParams({ department: departmentId }).toString() } : {}

  const { data, loading, error, refetch } = loadBotUsers(companyId, searchParams, departmentId)

  const [deleteBotUser] = makeDeleteBotUserMutation()

  function handleDepartmentChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    if (value) {
      setSearchParams({ department: event.target.value });
    } else {
      searchParams.delete('department');
      setSearchParams(searchParams);
    }
  }

  function onBotUserDelete(id: string) {
    if(window.confirm(t('are_you_sure'))) {
      deleteBotUser({variables: {input: {id}}})
          .then(({data}) => navigate(`/${companyId}/bot_users`))
    }
  }

  function onSearchSelect(id: string) {
    navigate(`/${companyId}/bot_users/${id}`)
  }

  function onExportClick() {
    if (departmentId) {
      window.open(`${location.pathname}/export?department=${departmentId}`, '_blank');
    } else {
      window.open(`${location.pathname}/export`, '_blank');
    }
  }

  const searchBotUsers = (loading || data == null ||  data!.company == null) ? [] : data!.company!.botUsersNameIds!.map((x) => ({id: x!!.id, name: x!.fullName}) );

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('bot_users')}
          </h1>

          <div className="flex items-center space-x-3">
            {!loading && (
              <SearchBox
                maxResults={1000000}
                className="w-64"
                options={searchBotUsers}
                disabled={loading}
                onSelect={onSearchSelect}
              />
            )}
            <div>
              {!loading && (
                <Select value={departmentId || ""} onChange={handleDepartmentChange}>
                  <option value="">
                    {t('all_departments')}
                  </option>
                  {data?.company!.departments.map(department => (
                    <option key={department.id} value={department.id} >
                      {department.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>

            <Button disabled={loading} onClick={() => navigate({
                pathname: 'new',
                ...createParams
              }, { state: { backgroundLocation: location } })}>
              {t('create_bot_user')}
            </Button>

            { departmentId &&
              <Button disabled={loading} onClick={() => navigate(`/${companyId}/departments/${departmentId}/import_bot_users`, { state: { backgroundLocation: location } })}>
                {t('import_bot_users')}
              </Button>
            }
            {departmentId &&
              <Button onClick={() => onExportClick()}>
                {t('export')}
              </Button>
            }
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <BotUserTable
            userRole={userRole}
            companyId={companyId}
            botUsers={data?.company!.botUsers}
            departmentId={departmentId}
            onDelete={onBotUserDelete}
            onCursorChange={setSearchParams}
          />
        )}
      </main>
    </div>
  );
}

export default BotUsersOverviewRoute;
