import React from 'react';
import { Controller, DefaultValues, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { gql } from '@apollo/client';
import { DepartmentForm_department } from '../__generated__/DepartmentForm_department';
import { Button, Appearance } from './Button';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { TextareaField } from './textarea-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField } from './select-field';
import * as yup from 'yup';
import {
  WhatsappBroadcastForm_whatsappBroadcastTemplates
} from "../__generated__/WhatsappBroadcastForm_whatsappBroadcastTemplates";
import { DepartmentLevel } from "../__generated__/globalTypes";
import {useTranslation} from "react-i18next";
import { NumberInputField } from "./NumberInputField";
import { ImageInputField } from "./ImageInputField";
import useDirectUploadMutation from "../hooks/direct-upload-mutation";
import {TagSelect} from "./TagSelect";
import {DepartmentsUpdateVisualsRouteQuery_department} from "../__generated__/DepartmentsUpdateVisualsRouteQuery";
import Editor from 'react-simple-wysiwyg';
import { BooleanInput } from './boolean-input-field';

const DepartmentSchema = yup.object({
  name: yup.string().required().label("Name"),
  price: yup.number().label("Price"),
  description: yup.string().label("description"),
  authorName: yup.string().label("authorName"),
  authorInformation: yup.string().label("authorInformation"),
  category: yup.string().label("category"),
  duration: yup.number().label("duration"),
  level: yup.string().oneOf(['unknown', 'all_levels', 'expert', 'basis']).nullable().label("level"),
  tags: yup.string().label("tags"),
  usp1: yup.string().label("usp1"),
  usp2: yup.string().label("usp2"),
  usp3: yup.string().label("usp3"),
  usp4: yup.string().label("usp4"),
  publicVisible: yup.boolean().label('publicVisible')
})


export interface DepartmentValues {
  name: string
  price: number | null
  description: string | null
  authorName: string | null
  authorInformation: string | null
  category: string | null
  duration: number | null
  level: DepartmentLevel | null
  tags: string | null
  usp1: string | null
  usp2: string | null
  usp3: string | null
  usp4: string | null
  publicVisible: boolean
  imageUrl: string | null
  image: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  backgroundImageUrl: string | null
  backgroundImage: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  authorImageUrl: string | null
  authorImage: {
    blobId: FileList | null | string
    _destroy: boolean
  }
}

export interface DepartmentFormProps {
  department?: DepartmentsUpdateVisualsRouteQuery_department
  onCancel: () => void;
  onSubmit: SubmitHandler<DepartmentValues>;
}

export const DepartmentEditVisualsForm: React.FC<DepartmentFormProps> = ({ department, onCancel, onSubmit } ) => {
  const defaultValues: DefaultValues<DepartmentValues> = {
    name: department?.name,
    price: department?.price || 0.0,
    description: department?.description || '',
    authorName: department?.authorName || '',
    authorInformation: department?.authorInformation || '',
    category: department?.category || '',
    duration: department?.duration || 0,
    level: department?.level || 'unknown',
    tags: department?.tags || '',
    usp1: department?.usp1 || '',
    usp2: department?.usp2 || '',
    usp3: department?.usp3 || '',
    usp4: department?.usp4 || '',
    slug: department?.slug || '',
    publicVisible: department?.publicVisible || false,
    image: {
      blobId: department?.imageUrl || null,
      _destroy: false
    },
    backgroundImage: {
      blobId: department?.backgroundImageUrl || null,
      _destroy: false
    },
    authorImage: {
      blobId: department?.authorImageUrl || null,
      _destroy: false
    },
  };

  const { register, handleSubmit, formState, setValue, getValues, control } = useForm<DepartmentValues>({ defaultValues, resolver: yupResolver(DepartmentSchema) })
  const { t, i18n } = useTranslation();
  const directUpload = useDirectUploadMutation()

  const onFormSubmit: SubmitHandler<DepartmentValues> = async values => {
    let signedBlobId = (values?.image.blobId instanceof FileList)
      ? await directUpload(values.image.blobId![0])
      : null
    let signedBackgroundBlobId = (values?.backgroundImage.blobId instanceof FileList)
      ? await directUpload(values.backgroundImage.blobId![0])
      : null
    let signedAuthorBlobId = (values?.authorImage.blobId instanceof FileList)
      ? await directUpload(values.authorImage.blobId![0])
      : null

    return onSubmit({ ...values, image: { _destroy: values.image._destroy, blobId: signedBlobId }, backgroundImage: { _destroy: values.backgroundImage._destroy, blobId: signedBackgroundBlobId }, authorImage: { _destroy: values.authorImage._destroy, blobId: signedAuthorBlobId }} )
  }

  return (
    <form className="leerlijn-inputs" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="">
        <div>
          <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
        </div>
        <div className="my-4">
          <TextInputField label={t('slug')} {...register('slug')} errors={formState.errors}/>
        </div>
        <div className="col-span-12">
          <label className="flex w-full py-4 justify-center items-baseline">
            <span className="flex-1 block text-sm font-medium text-gray-700">{t('public_visible')}
            </span>
            <div>
              <BooleanInput label={t('public_visible')} {...register('publicVisible')} errors={formState.errors} className={'mt-0'} />
            </div>
          </label>
        </div>
        <div className="my-4">
          <TextInputField label={t('author_name')} {...register('authorName')} errors={formState.errors}/>
        </div>
        <div className="my-4">
          <label class="mb-1 block text-sm font-medium text-gray-700" for="2">{t('author_information')}</label>
          <Controller
            control={control}
            name="authorInformation"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Editor value={value} onBlur={onBlur} onChange={onChange} />
            )} />
        </div>
        <div className="my-4">
          <ImageInputField
            label={t('department_author_image')}
            preview={department?.authorImageUrl}
            onRemove={() => { setValue('authorImage', { blobId: null, _destroy: true }, { shouldValidate: true }) }}
            errors={formState.errors}
            {...register('authorImage.blobId')}
          />
        </div>
        <div className="my-4">
          <label class="mb-1 block text-sm font-medium text-gray-700" for="2">{t('description')}</label>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Editor value={value} onBlur={onBlur} onChange={onChange} />
            )} />
        </div>
        <div className="my-4">
          <label className=" block text-sm font-medium text-gray-700">tags</label>
          <TagSelect label={t("tags")} tags={department?.tags} getValues={getValues} setValue={setValue} {...register('tags')} errors={formState.errors}>
          </TagSelect>
        </div>
        <div className="my-4">
          <TextInputField label={t('duration_minutes')} {...register('duration')} errors={formState.errors}/>
        </div>
        <div className="my-4">
          <NumberInputField step={0.01} label={t('price')} {...register('price')} errors={formState.errors} />
        </div>
        <div className="my-4">
          <SelectField label={t('levels')} {...register('level')} errors={formState.errors} >
            {['unknown', 'all_levels', 'expert', 'basis'].map(enumType => (
              <option key={enumType} value={enumType}>{t('department_level_' + enumType)}</option>
            ))}
          </SelectField>
        </div>

        <div className="my-4">
          <ImageInputField
            label={t('department_image')}
            preview={department?.imageUrl}
            onRemove={() => {setValue('image', {blobId: null, _destroy: true}, { shouldValidate: true })}}
            errors={formState.errors}
            {...register('image.blobId')}
          />
        </div>
        <div className="my-4">
          <TextareaField showHint={false} label={t('usp1')} rows={5} {...register('usp1')}
                         errors={formState.errors}/>
        </div>
        <div className="my-4">
          <TextareaField showHint={false} label={t('usp2')} rows={5} {...register('usp2')}
                         errors={formState.errors}/>
        </div>
        <div className="my-4">
          <TextareaField showHint={false} label={t('usp3')} rows={5} {...register('usp3')}
                         errors={formState.errors}/>
        </div>
        <div className="my-4">
          <TextareaField showHint={false} label={t('usp4')} rows={5} {...register('usp4')}
                         errors={formState.errors}/>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default DepartmentEditVisualsForm
