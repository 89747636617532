/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CardType {
  IMAGE = "IMAGE",
  OPEN = "OPEN",
  QUIZ = "QUIZ",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum DepartmentDesiredKind {
  available_desired = "available_desired",
  default_desired = "default_desired",
  scheduled_desired = "scheduled_desired",
}

export enum DepartmentKind {
  available = "available",
  default = "default",
  scheduled = "scheduled",
  template = "template",
}

export enum DepartmentLevel {
  all_levels = "all_levels",
  basis = "basis",
  expert = "expert",
  unknown = "unknown",
}

/**
 * Autogenerated input type of AttachUserMutation
 */
export interface AttachUserMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  userId: string;
}

/**
 * Autogenerated input type of CloneBotModuleMutation
 */
export interface CloneBotModuleMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  id: string;
  name: string;
}

export interface ContactPersonInput {
  name?: string | null;
  phone?: string | null;
}

/**
 * Autogenerated input type of CreatCompanyMutation
 */
export interface CreatCompanyMutationInput {
  accountId: string;
  apiKey: string;
  clientMutationId?: string | null;
  companyName: string;
  email: string;
  mobile: string;
}

/**
 * Autogenerated input type of CreatUserMutation
 */
export interface CreatUserMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  email: string;
  name: string;
  password: string;
  role: string;
  username: string;
}

/**
 * Autogenerated input type of CreateBotModuleMutation
 */
export interface CreateBotModuleMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  folderId?: string | null;
  name?: string | null;
  themeId?: string | null;
}

/**
 * Autogenerated input type of CreateBotUserMutation
 */
export interface CreateBotUserMutationInput {
  botUserType: string;
  clientMutationId?: string | null;
  companyId: string;
  departmentId: string;
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  testUser: boolean;
}

/**
 * Autogenerated input type of CreateCardMutation
 */
export interface CreateCardMutationInput {
  botModuleId?: string | null;
  cardType: CardType;
  clientMutationId?: string | null;
  image?: FileInput | null;
  mediaMessageText?: string | null;
  mediaMessageTyping?: number | null;
  qaModuleId?: string | null;
  questions?: QuestionInput[] | null;
  quizMessageThreshold?: number | null;
  textMessageText?: TextMessageTextInput[] | null;
  title: string;
  video?: FileInput | null;
}

/**
 * Autogenerated input type of CreateDepartmentBotModuleMutation
 */
export interface CreateDepartmentBotModuleMutationInput {
  botModuleId: string;
  clientMutationId?: string | null;
  departmentId: string;
}

/**
 * Autogenerated input type of CreateDepartmentMutation
 */
export interface CreateDepartmentMutationInput {
  clientMutationId?: string | null;
  closureMessage: string;
  companyId: string;
  description?: string | null;
  desiredKind?: DepartmentDesiredKind | null;
  image?: FileInput | null;
  kind?: DepartmentKind | null;
  language: string;
  name: string;
  price?: number | null;
  showQaModules: boolean;
  whatsappBroadcastTemplateId?: string | null;
}

/**
 * Autogenerated input type of CreateDirectUploadMutation
 */
export interface CreateDirectUploadMutationInput {
  byteSize: number;
  checksum: string;
  clientMutationId?: string | null;
  contentType: string;
  filename: string;
}

/**
 * Autogenerated input type of CreateFolderMutation
 */
export interface CreateFolderMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  name: string;
}

/**
 * Autogenerated input type of CreateQaModuleMutation
 */
export interface CreateQaModuleMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  name?: string | null;
  tags?: string | null;
}

/**
 * Autogenerated input type of CreateSubscriptionMutation
 */
export interface CreateSubscriptionMutationInput {
  automaticRenewal?: boolean | null;
  botSessionExpirationHours?: number | null;
  clientMutationId?: string | null;
  companyIds: string[];
  credits: number;
  expirationMonths?: number | null;
  fixedPrice?: number | null;
  freeSessions?: number | null;
  name: string;
  pricePerSession?: number | null;
  qaExpirationHours?: number | null;
}

/**
 * Autogenerated input type of CreateThemeMutation
 */
export interface CreateThemeMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  name: string;
  parentId?: string | null;
}

/**
 * Autogenerated input type of DeleteBotModuleMutation
 */
export interface DeleteBotModuleMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteBotUserMutation
 */
export interface DeleteBotUserMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteCardMutation
 */
export interface DeleteCardMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteDepartmentBotModuleMutation
 */
export interface DeleteDepartmentBotModuleMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteDepartmentMutation
 */
export interface DeleteDepartmentMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteFolderMutation
 */
export interface DeleteFolderMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteQaModuleMutation
 */
export interface DeleteQaModuleMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteThemeMutation
 */
export interface DeleteThemeMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteUserMutation
 */
export interface DeleteUserMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteWhatsappBroadcastMutation
 */
export interface DeleteWhatsappBroadcastMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DetachUserMutation
 */
export interface DetachUserMutationInput {
  clientMutationId?: string | null;
  companyId: string;
  id: string;
}

export interface DialogflowConfigurationInput {
  clientEmail: string;
  privateKey: string;
  projectId: string;
}

export interface FileInput {
  _destroy?: boolean | null;
  blobId?: string | null;
}

/**
 * Autogenerated input type of ManageBotUsersDepartmentMutation
 */
export interface ManageBotUsersDepartmentMutationInput {
  botUserIds: string[];
  clientMutationId?: string | null;
  id: string;
}

export interface QuestionInput {
  _destroy?: boolean | null;
  answerCorrectFeedback?: string | null;
  answerWrongFeedback?: string | null;
  companyId?: string | null;
  id?: string | null;
  image?: FileInput | null;
  openQuestionFeedback?: string | null;
  openQuestionTyping?: number | null;
  question: string;
  questionOptions?: QuestionOptionInput[] | null;
  typingCorrect?: number | null;
  typingWrong?: number | null;
}

export interface QuestionOptionInput {
  _destroy?: boolean | null;
  correct?: boolean | null;
  id?: string | null;
  text: string;
}

/**
 * Autogenerated input type of SendWhatsappBroadcastMutation
 */
export interface SendWhatsappBroadcastMutationInput {
  botUserIds: string[];
  clientMutationId?: string | null;
  companyId: string;
  departmentIds: string[];
  scheduledAt?: any | null;
  whatsappBroadcastTemplateId: string;
}

export interface TextMessageTextInput {
  text: string;
  typing: number;
}

/**
 * Autogenerated input type of UpdateBotModuleMutation
 */
export interface UpdateBotModuleMutationInput {
  cardIds?: string[] | null;
  clientMutationId?: string | null;
  folderId?: string | null;
  id: string;
  name?: string | null;
  themeId?: string | null;
}

/**
 * Autogenerated input type of UpdateBotUserMutation
 */
export interface UpdateBotUserMutationInput {
  botUserType: string;
  clientMutationId?: string | null;
  companyId: string;
  departmentId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobile: string;
  testUser: boolean;
}

/**
 * Autogenerated input type of UpdateCardMutation
 */
export interface UpdateCardMutationInput {
  cardType: CardType;
  clientMutationId?: string | null;
  id: string;
  image?: FileInput | null;
  mediaMessageText?: string | null;
  mediaMessageTyping?: number | null;
  questions?: QuestionInput[] | null;
  quizMessageThreshold?: number | null;
  textMessageText?: TextMessageTextInput[] | null;
  title: string;
  video?: FileInput | null;
}

/**
 * Autogenerated input type of UpdateCompanyMutation
 */
export interface UpdateCompanyMutationInput {
  avatar?: FileInput | null;
  clientMutationId?: string | null;
  contactPerson?: ContactPersonInput | null;
  dialogflow?: DialogflowConfigurationInput | null;
  email?: string | null;
  id: string;
  language?: string | null;
  name?: string | null;
  profile?: FileInput | null;
  shortCode?: string | null;
  whatsapp?: WhatsappConfigurationInput | null;
}

/**
 * Autogenerated input type of UpdateDepartmentBotModuleMutation
 */
export interface UpdateDepartmentBotModuleMutationInput {
  botModuleId: string;
  clientMutationId?: string | null;
  departmentId: string;
  endDate?: string | null;
  endTime?: number | null;
  sendAtHour?: number | null;
  sentAtOffsetDays?: number | null;
  startDate?: string | null;
  startTime?: number | null;
}

/**
 * Autogenerated input type of UpdateDepartmentConnectBotModuleMutation
 */
export interface UpdateDepartmentConnectBotModuleMutationInput {
  clientMutationId?: string | null;
  departmentBotModuleIds?: string[] | null;
  id: string;
}

/**
 * Autogenerated input type of UpdateDepartmentMutation
 */
export interface UpdateDepartmentMutationInput {
  clientMutationId?: string | null;
  closureMessage: string;
  description?: string | null;
  desiredKind?: DepartmentDesiredKind | null;
  id: string;
  image?: FileInput | null;
  kind?: DepartmentKind | null;
  language: string;
  name: string;
  price?: number | null;
  showQaModules: boolean;
  whatsappBroadcastTemplateId?: string | null;
}

/**
 * Autogenerated input type of UpdateDepartmentVisualsMutation
 */
export interface UpdateDepartmentVisualsMutationInput {
  authorImage?: FileInput | null;
  authorInformation?: string | null;
  authorName?: string | null;
  backgroundImage?: FileInput | null;
  category?: string | null;
  clientMutationId?: string | null;
  description?: string | null;
  duration?: number | null;
  id: string;
  image?: FileInput | null;
  level?: DepartmentLevel | null;
  name: string;
  price?: number | null;
  publicVisible: boolean;
  slug: string;
  tags?: string | null;
  usp1?: string | null;
  usp2?: string | null;
  usp3?: string | null;
  usp4?: string | null;
}

/**
 * Autogenerated input type of UpdateFolderMutation
 */
export interface UpdateFolderMutationInput {
  clientMutationId?: string | null;
  id: string;
  name: string;
}

/**
 * Autogenerated input type of UpdateQaModuleMutation
 */
export interface UpdateQaModuleMutationInput {
  cardIds?: string[] | null;
  clientMutationId?: string | null;
  id: string;
  name?: string | null;
  tags?: string | null;
}

/**
 * Autogenerated input type of UpdateSubscriptionMutation
 */
export interface UpdateSubscriptionMutationInput {
  automaticRenewal?: boolean | null;
  botSessionExpirationHours?: number | null;
  clientMutationId?: string | null;
  companyIds: string[];
  credits: number;
  expirationMonths?: number | null;
  fixedPrice?: number | null;
  freeSessions?: number | null;
  id: string;
  name: string;
  pricePerSession?: number | null;
  qaExpirationHours?: number | null;
}

/**
 * Autogenerated input type of UpdateThemeMutation
 */
export interface UpdateThemeMutationInput {
  clientMutationId?: string | null;
  id: string;
  name: string;
  parentId?: string | null;
}

export interface WhatsappConfigurationInput {
  accountId: string;
  apiKey: string;
  authentication?: string | null;
  mobile: string;
  providerId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
