import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { CompanyDetailsForm_UpdateCompanyMutation, CompanyDetailsForm_UpdateCompanyMutationVariables } from '../__generated__/CompanyDetailsForm_UpdateCompanyMutation';
import { CompanyDetailsForm_company } from '../__generated__/CompanyDetailsForm_company';
import { Button } from './Button';
import { FileInputField } from './FileInputField';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import useDirectUploadMutation from '../hooks/direct-upload-mutation';
import { VFCwF } from '../types';
import { ImageInputField, imageTypeTest, imageSizeTest } from './ImageInputField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

const CompanyDetailsSchema = yup.object({
  name: yup.string().required().label("Name"),
  language: yup.string().oneOf(['en', 'nl']).required().label("Language"),
  email: yup.string().required().label("Email"),
  avatar: yup.object({
    blobId: yup.mixed().test(imageTypeTest).test(imageSizeTest).label("Avatar"),
  }),
  profile: yup.object({
    blobId: yup.mixed().test(imageTypeTest).test(imageSizeTest).label("Profile"),
  }),
  contactPerson: yup.object({
    name: yup.string().label("Name"),
    phone: yup.string().label("Phone")
  })
})

export interface CompanyDetailsValues {
  name: string
  shortCode: string | null
  language: string
  email: string
  avatarUrl: string | null
  avatar: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  profileUrl: string | null
  profile: {
    blobId: FileList | null | string
    _destroy: boolean
  }
  contactPerson: {
    name: string | null
    phone: string | null
  }
}

export interface CompanyDetailsFormProps {
  company: CompanyDetailsForm_company
}

export const CompanyDetailsForm: VFCwF<CompanyDetailsFormProps> = ({ company }) => {
  const { t, i18n } = useTranslation();
  const defaultValues: DefaultValues<CompanyDetailsValues> = {
    name: company.name,
    shortCode: company.shortCode,
    email: company.email ?? undefined,
    language: company.language || 'nl',
    avatar: {
      blobId: company?.avatarUrl || null,
      _destroy: false
    },
    profile: {
      blobId: company?.profileUrl || null,
      _destroy: false
    },
    contactPerson: {
      name: company.contactPerson?.name,
      phone: company.contactPerson?.phone
    }
  }
  const { register, handleSubmit, formState, setValue } = useForm<CompanyDetailsValues>({ defaultValues, resolver: yupResolver(CompanyDetailsSchema) })
  const directUpload = useDirectUploadMutation()

  const [updateCompany] = useMutation<CompanyDetailsForm_UpdateCompanyMutation, CompanyDetailsForm_UpdateCompanyMutationVariables>(
    gql`
      mutation CompanyDetailsForm_UpdateCompanyMutation($input: UpdateCompanyMutationInput!) {
        updateCompany(input: $input) {
          company {
            id
          }
          errors
        }
      }
    `
  )

  const onSubmit: SubmitHandler<CompanyDetailsValues> = async (values) => {
    let [avatarBlobId, profileBlobId] = await Promise.all([
      values?.avatar.blobId instanceof FileList
        ? directUpload(values.avatar.blobId![0])
        : null,
      values?.profile.blobId instanceof FileList
        ? await directUpload(values.profile.blobId![0])
        : null,
    ])

    await updateCompany({
      variables: {
        input: { id: company.id, ...values,
          avatar: { _destroy: values.avatar._destroy, blobId: avatarBlobId },
          profile: { _destroy: values.profile._destroy, blobId: profileBlobId }
        }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-4">
              <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
            </div>
            <div className="col-span-4">
              <TextInputField label={t('short_code')}{...register('shortCode')} errors={formState.errors} />
            </div>
            <div className="col-span-2">
              <SelectField label="Language" {...register('language')} errors={formState.errors} >
                <option value="en">{t('english')}</option>
                <option value="nl">{t('nederland')}</option>
              </SelectField>
            </div>
            <div className="col-span-6">
              <TextInputField label={t('email')} type="email" {...register('email')} errors={formState.errors} />
            </div>
            <div className="col-span-6">
              <ImageInputField
                label={t('profile_image')}
                preview={company.profileUrl}
                onRemove={() => {setValue('profile', {blobId: null, _destroy: true}, { shouldValidate: true })}}
                errors={formState.errors}
                {...register('profile.blobId')}
              />
            </div>
            <div className="col-span-6">
              <ImageInputField
                label={t('avatar_image')}
                preview={company.avatarUrl}
                onRemove={() => {setValue('avatar', {blobId: null, _destroy: true}, { shouldValidate: true })}}
                errors={formState.errors}
                {...register('avatar.blobId')}
              />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('contact_person_name')} {...register('contactPerson.name')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('contact_person_phone')} {...register('contactPerson.phone')} errors={formState.errors} />
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

CompanyDetailsForm.fragments = {
  company: gql`
    fragment CompanyDetailsForm_company on Company {
      id
      name
      shortCode
      language
      email
      profileUrl
      avatarUrl

      contactPerson {
        name
        phone
      }
    }
  `
}
