import * as React from 'react'
import { useId } from '@reach/auto-id'
import {ErrorMessage} from "@hookform/error-message";
import {WhatsappBroadcastForm_departments} from "../../__generated__/WhatsappBroadcastForm_departments";
import {useTranslation} from "react-i18next";
import {useState} from "react";

export interface DepartmentCheckboxListProps {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  departments: WhatsappBroadcastForm_departments[]
}

export const DepartmentCheckboxList = React.forwardRef<HTMLInputElement, DepartmentCheckboxListProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, setValue, getValues, className, style, label, name, errors, departments, onChange } = props
  const myRef = ref;
  let departmentIds = departments.map(x => x.id);
  let selectedDepartmentIds = getValues("departmentIds")
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState("");

  const toggleAllDepartments = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (departmentIds.length == selectedDepartmentIds.length){
      selectedDepartmentIds = []
      setValue("departmentIds", selectedDepartmentIds)
      onChange(event)
    } else {
      selectedDepartmentIds = departmentIds
      setValue("departmentIds", selectedDepartmentIds)
      onChange(event)
    }
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newId = event.currentTarget.value;
    let selectedDepartmentIds = getValues("departmentIds")
    if(!selectedDepartmentIds.includes(newId)){
      selectedDepartmentIds.push(newId);
    }else{
      selectedDepartmentIds.splice(selectedDepartmentIds.indexOf(newId), 1);  //deleting
    }

    setValue("departmentIds", selectedDepartmentIds.length > 0 ? selectedDepartmentIds : []);
    onChange(event)
  };

  return (
    <div ref={ref} className="" style={style}>
      <label className="block font-medium" htmlFor={id}>
        {label}
      </label>
      <div>
        <div className="my-2">
          <label className="block text-sm font-medium text-gray-700" htmlFor="43">
            {t('search')}
            <span> ({t('selected')}: </span>
            <span>{selectedDepartmentIds?.filter(x => x.length > 0)?.length})</span>
          </label>
          <input type="search" value={filter} onChange={e => setFilter(e.target.value)} className="w-20 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:number-sm border-gray-300 rounded-md"/>
        </div>
      </div>
      <div ref={ref} className="block w-full pl-3 pr-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border" style={style}>
        <div className="mb-3">
          <label className="flex w-full" >
            <span className="flex-1">{t('toggle_all_departments')}</span>
            <input type="checkbox" checked={departmentIds.length == selectedDepartmentIds.length} onChange={toggleAllDepartments}>
            </input>
          </label>
        </div>
        <div>
          {departments.filter(x => x.kind != 'template' && (filter == '' || x.name.toLowerCase().includes(filter.toLowerCase()))).map(department => (
            <div key={department.id}>
              <label className="flex w-full">
                <div className="flex-1 inline-tags">
                  <span className="label">{department.name}</span>
                  <span className="tag">
                    <span>{department.language} </span>
                  </span>
                  <span className="tag">
                    <span>{department.kind} </span>
                  </span>
                </div>
                <input type="checkbox" checked={selectedDepartmentIds.includes(department.id)} onChange={onChangeSelect} key={department.id} value={department.id}>
                </input>
              </label>
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
})
