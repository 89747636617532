import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import { ChatAltIcon, FolderIcon } from '@heroicons/react/outline'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import { BotModulesOverviewRoute_BotModuleTable_botModules } from '../__generated__/BotModulesOverviewRoute_BotModuleTable_botModules';
import { BotModulesOverviewRoute_BotModuleTable_folders } from '../__generated__/BotModulesOverviewRoute_BotModuleTable_folders';
import { BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule } from '../__generated__/BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule';
import { BotModulesOverviewRoute_BotModuleTableFolderRow_folder } from '../__generated__/BotModulesOverviewRoute_BotModuleTableFolderRow_folder';
import { BotModulesOverviewRoute_DeleteBotModuleMutation, BotModulesOverviewRoute_DeleteBotModuleMutationVariables } from '../__generated__/BotModulesOverviewRoute_DeleteBotModuleMutation';
import { BotModulesOverviewRoute_DeleteFolderMutation, BotModulesOverviewRoute_DeleteFolderMutationVariables } from '../__generated__/BotModulesOverviewRoute_DeleteFolderMutation';
import { BotModulesOverviewRouteQuery } from '../__generated__/BotModulesOverviewRouteQuery';
import { SearchBox } from '../components/Searchbox';
import { Button } from '../components/Button'
import useHasChanged from '../hooks/has-changed'
import { VFCwF } from '../types'
import {useTranslation} from "react-i18next";

interface BotModuleTableBotModuleRowProps {
  userRole: string | null
  botModule: BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule
  onDelete: (id: string) => void
}

const BotModuleTableBotModuleRow: VFCwF<BotModuleTableBotModuleRowProps> = ({ userRole, botModule, onDelete }) => {
  const { t, i18n } = useTranslation();

  let location = useLocation();
  if (botModule.fromTemplate == true){
    return (<></>);
  }

  return (
    <tr>
      <td className="pl-4 py-3 text-sm text-gray-400 font-medium">
        <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Bot module</span>
      </td>
      <th
        scope="row"
        className="pl-4 pr-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900"
      >
        {botModule.fromTemplate == false ? (
        <Link
          className="block truncate hover:text-gray-600"
          to={botModule.id}
        >
          {botModule.name}
        </Link>
          ) : (
            <span>{botModule.name}</span>
          )}
      </th>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
        {botModule.fromTemplate == false &&
          <Menu>
            <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" />
            </MenuButton>
            <MenuList className="w-40">
              <MenuLink as={Link} to={botModule.id}>
                {t('details')}
              </MenuLink>
              <MenuLink as={Link} to={`${botModule.id}/edit`} state={{ backgroundLocation: location }}>
                {t('edit')}
              </MenuLink>
              <MenuLink as={Link} to={`${botModule.id}/clone`} state={{ backgroundLocation: location }}>
                {t('clone')}
              </MenuLink>
              <MenuItem onSelect={() => onDelete(botModule.id)}>
                {t('delete')}
              </MenuItem>
            </MenuList>
          </Menu>
        }
      </td>
    </tr>
  )
}

BotModuleTableBotModuleRow.fragments = {
  botModule: gql`
    fragment BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule on BotModule {
      id
      name
      fromTemplate
    }
  `
}

interface BotModuleTableFolderRowProps {
  folder: BotModulesOverviewRoute_BotModuleTableFolderRow_folder;
  onDelete: (id: string) => void
}

const BotModuleTableFolderRow: VFCwF<BotModuleTableFolderRowProps> = ({ folder, onDelete }) => {
  let location = useLocation()
  const { t, i18n } = useTranslation();

  return (
    <tr>
      <td className="pl-4 py-3 text-sm text-gray-400 font-medium">
        <FolderIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Folder</span>
      </td>
      <th
        scope="row"
        className="pl-4 pr-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900"
      >
        <Link
          className="block truncate hover:text-gray-600"
          to={`/${folder.companyId}/bot_modules?folder=${folder.id}`}
        >
          {folder.name}
        </Link>
      </th>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
        <Menu>
          <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" />
          </MenuButton>
          <MenuList className="w-40">
            <MenuLink as={Link} to={`/${folder.companyId}/folders/${folder.id}/edit`} state={{ backgroundLocation: location }}>
              {t('edit')}
            </MenuLink>
            <MenuItem onSelect={() => onDelete(folder.id)}>
              {t('delete')}
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </tr>
  )
}

BotModuleTableFolderRow.fragments = {
  folder: gql`
    fragment BotModulesOverviewRoute_BotModuleTableFolderRow_folder on Folder {
      id
      name
      companyId
    }
  `
}

interface BotModuleTableProps {
  userRole: string;
  botModules: BotModulesOverviewRoute_BotModuleTable_botModules[]
  folders: BotModulesOverviewRoute_BotModuleTable_folders[]
  folderId: string | null;
  onDeleteBotModule: (id: string) => void
  onDeleteFolder: (id: string) => void
}

const BotModuleTable: VFCwF<BotModuleTableProps> = ({ userRole, botModules, folders, folderId, onDeleteBotModule, onDeleteFolder }) => {
  const { t, i18n } = useTranslation();

  let filteredBotModules = React.useMemo(() => {
    return botModules.filter(botModule => botModule.folderId === folderId);
  }, [folderId, botModules])

  return (
    <div className="overflow-auto border border-gray-200 rounded-lg relative">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="pl-4 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              <span className="sr-only">Type</span>
            </th>
            <th
              scope="col"
              className="pl-4 pr-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              {t('name')}
            </th>
            <th
              scope="col"
              className="pr-8 py-3 border-b border-gray-200 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              <span className="sr-only">{t('actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100">
          {!folderId && (
            folders.map(folder => (
              <BotModuleTableFolderRow key={folder.id} folder={folder} onDelete={onDeleteFolder} />
            )
          ))}

          {filteredBotModules.length ? filteredBotModules.map(botModule => (
            <BotModuleTableBotModuleRow userRole={userRole} key={botModule.id} botModule={botModule} onDelete={onDeleteBotModule} />
          )) : (
            <tr>
              <td />
              <td className="py-3 pl-4 pr-8 whitespace-nowrap text-sm text-gray-500 font-medium" colSpan={3}>
                {t('no_bot_modules_created_yet')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

BotModuleTable.fragments = {
  botModules: gql`
    fragment BotModulesOverviewRoute_BotModuleTable_botModules on BotModule {
      id
      folderId
      ...BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule
    }

    ${BotModuleTableBotModuleRow.fragments.botModule}
  `,
  folders: gql`
    fragment BotModulesOverviewRoute_BotModuleTable_folders on Folder {
      id
      ...BotModulesOverviewRoute_BotModuleTableFolderRow_folder
    }

    ${BotModuleTableFolderRow.fragments.folder}
  `
}

interface BotModulesOverviewRouteProps {
  companyId: string
  userRole: string
}

const BotModulesOverviewRoute: React.VFC<BotModulesOverviewRouteProps> = ({ companyId, userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const searchParams = new URLSearchParams((state?.backgroundLocation || location).search);
  const folderId = searchParams.get('folder');

  const { data, loading, error, refetch } = useQuery<BotModulesOverviewRouteQuery>(gql`
    query BotModulesOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id

        botModules {
          ...BotModulesOverviewRoute_BotModuleTable_botModules
        }

        folders {
          ...BotModulesOverviewRoute_BotModuleTable_folders
        }
      }
    }

    ${BotModuleTable.fragments.botModules}
    ${BotModuleTable.fragments.folders}
  `, {
    variables: {
      companyId
    }
  });

  const [deleteBotModule] = useMutation<BotModulesOverviewRoute_DeleteBotModuleMutation, BotModulesOverviewRoute_DeleteBotModuleMutationVariables>(gql`
    mutation BotModulesOverviewRoute_DeleteBotModuleMutation($input: DeleteBotModuleMutationInput!) {
      deleteBotModule(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['BotModulesOverviewRouteQuery']
  })

  const [deleteFolder] = useMutation<BotModulesOverviewRoute_DeleteFolderMutation, BotModulesOverviewRoute_DeleteFolderMutationVariables>(gql`
    mutation BotModulesOverviewRoute_DeleteFolderMutation($input: DeleteFolderMutationInput!) {
      deleteFolder(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['BotModulesOverviewRouteQuery']
  })

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch bot modules when the modal is closed by a navigate action.
      refetch()
    }
  });

  function onBotModuleDelete(id: string) {
    deleteBotModule({ variables: { input: { id } } })
  }

  function onSearchSelect(id: string) {
    navigate(`/${companyId}/bot_modules/${id}`)
  }

  async function onFolderDelete(id: string) {
    await deleteFolder({ variables: { input: { id } } })
    navigate(`/${companyId}/bot_modules`)
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            {folderId ? (
              <React.Fragment>
                <Link to={`/${companyId}/bot_modules`} className="hover:text-gray-400">
                  {t('bot_modules')}
                </Link>

                <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>

                <h1 className="text-gray-900 truncate">
                  {data
                    ? data.company!.folders.find(folder => folder.id === folderId)?.name
                    : '...'
                  }
                </h1>
              </React.Fragment>
            ) : (
              <h1 className="text-2xl font-semibold text-gray-900 truncate">
                {t('bot_modules')}
              </h1>
            )}
          </div>

          <div className="flex items-center space-x-3">
            <SearchBox
              className="w-64"
              options={data?.company!.botModules ?? []}
              disabled={loading}
              onSelect={onSearchSelect}
            />

            <Button onClick={() => navigate(folderId ? `new?folder=${folderId}` : 'new', { state: { backgroundLocation: location } })}>
              {t('create_bot_modules')}
            </Button>

            {!folderId && (
              <Button onClick={() => navigate(`/${companyId}/folders/new`, { state: { backgroundLocation: location } })}>
                {t('create_folder')}
              </Button>
            )}
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <BotModuleTable
            userRole={userRole}
            botModules={data!.company!.botModules}
            folders={data!.company!.folders}
            folderId={folderId}
            onDeleteBotModule={onBotModuleDelete}
            onDeleteFolder={onFolderDelete}
          />
        )}
      </main>
    </div>
  );
}

export default BotModulesOverviewRoute;
