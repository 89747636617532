import * as React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {DepartmentsUpdateVisualsRouteQuery} from "../__generated__/DepartmentsUpdateVisualsRouteQuery";
import {
  DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutation,
  DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutationVariables
} from "../__generated__/DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutation";

import DepartmentEditVisualsForm, {DepartmentValues} from "../components/DepartmentEditVisualsForm";

interface DepartmentsEditVisualsRouteProps {
    companyId: string;
}

const DepartmentsEditVisualsRoute: React.FC<DepartmentsEditVisualsRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const {departmentId} = useParams<'departmentId'>();
  const {t, i18n} = useTranslation();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)
  const [notification, setNotification] = useState('')

  const {data, loading, error} = useQuery<DepartmentsUpdateVisualsRouteQuery>(gql`
      query DepartmentsUpdateVisualsRouteQuery($departmentId: ID!) {
        department(id: $departmentId) {
          id
          companyId
          publicVisible
          name
          price 
          description
          authorName
          authorInformation
          category
          duration
          level
          tags
          usp1
          usp2
          usp3
          usp4
          slug
          imageUrl
          backgroundImageUrl
          authorImageUrl
          botModulesCount
          createdAt
        }
      }
    `, {
      variables: {
        departmentId: departmentId as string
      }
    }
  );

  function onCancel() {
    navigate(`/${companyId}/departments`)
  }
  async function onSubmit(values: DepartmentValues) {
    let { data } = await updateDepartment({ variables: { input: { id: departmentId as string, ...values } } })
    if (data?.updateDepartmentVisuals?.errors.length == 0) {
      setNotification(t('updated'))
      const iframe = document.getElementById('iframe')
      if (iframe) {
        iframe.src = iframe.src
      }

    } else {
      setMutationErrors(data?.updateDepartmentVisuals?.errors!)
    }
  }

  const [updateDepartment] = useMutation<DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutation, DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutationVariables>(gql`
      mutation DepartmentsEditVisualsRoute_UpdateDepartmentVisualsMutation($input: UpdateDepartmentVisualsMutationInput!) {
        updateDepartmentVisuals(input: $input) {
          department {
            id
            companyId
            publicVisible
            name
            price 
            description
            authorName
            authorInformation
            category
            duration
            level
            tags
            usp1
            usp2
            usp3
            usp4
            slug
            imageUrl
            backgroundImageUrl
            authorImageUrl
          }
          errors
        }
      }
    `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'DepartmentsUpdateRouteQuery',
      'DepartmentsOverviewRouteQuery'
    ]
  })

  if (error) {
    debugger
  }

  if (data?.department == null){
    return (<></>)
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/departments`} className="hover:text-gray-400">
              {t('departments')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                 viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
            </svg>

            <h1 className="text-gray-900 truncate">
              {t('editing')} {data?.department?.name ?? '...'}
            </h1>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading && <p>{t('loading')}</p>}
        {error && <p>{t('error')}</p>}
        {(!data || !data.department || data.department.companyId !== companyId) && <p>{t('not_found')}</p>}
        {data?.department && (
          <div className="leerlijn-form">

            {mutationErrors && mutationErrors.length > 0 &&
              <div className="fixed bottom-10 z-50 right-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
                <div className="text-sm text-white">
                  <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
                  {mutationErrors.join(', ')}
                </div>
              </div>
            }
            {notification && notification.length > 0 &&
              <div className="fixed bottom-10 z-50 right-10 px-5 py-4 bg-green-500 bg-white drop-shadow-lg">
                <div className="text-sm text-white">
                  <span className="mr-4 inline-block px-3 py-1 rounded-full bg-green-500 text-white drop-shadow-lg font-extrabold">i</span>
                  {notification}
                </div>
              </div>
            }
            <DepartmentEditVisualsForm
              department={data.department}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
            <div className="leerlijn-preview">
                {data.department?.id ? (
                <iframe scrolling="no" id="iframe" className="w-100" src={`/shop/companies/${companyId}/departments/${data.department.id || 0}`}></iframe>
              ) : (
                <span>Save to show preview</span>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

export default DepartmentsEditVisualsRoute;
