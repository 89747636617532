import * as React from 'react'
import { useId } from '@reach/auto-id'
import { FormField, FormFieldProps } from './FormField'
import useInnerRef from '../hooks/useInnerRef'
import { Button, Appearance, Intent, Size } from './Button';
import { SwitchVerticalIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'
import {useTranslation} from "react-i18next";

export interface FileInputPreviewProps {
  src: Blob | string
  component?: string | React.ComponentType<{ className?: string, src: string }>
  onRemove: () => void
}

export const FileInputPreview: React.FC<FileInputPreviewProps> = ({ src, component: Component = 'img', onRemove }) => {
  const [source, setSource] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (typeof src === 'string') {
      setSource(src)
      return
    }

    // @TODO Clean listener on unmount...
    let reader = new FileReader()
    reader.onloadend = () => setSource(reader.result as string)
    reader.readAsDataURL(src)
  }, [src])

  return (
    source ? <Component className="h-full overflow-hidden aspect-auto" src={source} /> : null
  )
}

export interface FileInputProps extends React.InputHTMLAttributes<Omit<HTMLInputElement, 'multiple' | 'type'>> {
  inputhint?: string
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const innerRef = useInnerRef<HTMLInputElement>(ref, null)
  const { className, ...inputProps } = props
  const { t, i18n } = useTranslation();

  const onUploadClick = () => {
    innerRef.current!.click()
  }

  return (
    <>
      <div className="h-full flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <div
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              onClick={onUploadClick}
            >
              <span>{t('upload')}</span>
              <input ref={innerRef} type="file" className="sr-only" accept={props.accept} {...inputProps} />
            </div>
            <p className="pl-1">{t('or_drag_and_drop')}</p>
          </div>
          {props.inputhint &&
            <p className="text-xs text-gray-500">{props.inputhint}</p>}
        </div>
      </div>
    </>
  )
})

export interface FileInputFieldProps extends Omit<FormFieldProps, 'labelFor'>, FileInputProps {
  preview?: Blob | string | null
  previewComponent?: string | React.ComponentType<{ src: string }>
  onRemove?: () => void
  name: string
  accept?: string
  inputhint?: string
}

export const FileInputField = React.forwardRef<HTMLInputElement, FileInputFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, label, preview = null, previewComponent, onChange: unusedOnChange, onRemove: unusedOnRemove, ...rest } = props
  const [src, setSrc] = React.useState<Blob | string | null>(preview)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSrc(event.target.files![0])

    if (props.onChange) {
      props.onChange(event)
    }
  }

  const onRemove = () => {
    setSrc(null)

    if (props.onRemove) {
      props.onRemove()
    }
  }

  React.useEffect(() => {
    setSrc(preview)
  }, [preview])

  return (
    <FormField label={label} labelFor={id} name={props.name} errors={props.errors}>
      {src && (
        <div className="flex justify-end">
          <Button type="button" appearance={Appearance.Outline} intent={Intent.Danger} size={Size.S} onClick={() => onRemove()}>
            <TrashIcon className="w-4 h-4"/>
          </Button>
        </div>)
          }
      <div className="max-w-lg h-80 rounded-md">
        {src ? (
          <FileInputPreview src={src} component={previewComponent} onRemove={onRemove} />
        ) : (
          <FileInput ref={ref} id={id} onChange={onChange} {...rest} />
        )}
      </div>
    </FormField>
  )
})
